// Primary Color Mixin

@mixin journal-color($banner-color, $banner-path: 'AO-Homebanner.jpg') {
  //$highlights-color: #545454;
  $highlights-color: #7800cc;
  $highlights-color-light: #9475ff;
  .bg-color {
    background: $banner-color;
  }
  .banner-color {
    background: $highlights-color;
  }
  .font-color {
    //color: $banner-color;
    color: $highlights-color;
  }
  .hover-color {
    background: lighten($banner-color, 6%);
  }
  .secondary-nav-list.secondary-nav-list.secondary-nav-list {
    background: #262626;
    .active { background-color: rgba(255, 255, 255, 0.2); }
  }
  .about-journal h3 {
    background: white;
    font-weight: 500;
    color: black;
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
  }
  //adding color for submit-paper link
  .sidebar-section-title.submit-paper {
    a {
      color: white;
      text-transform: uppercase;
      //background-color: $banner-color;
      //padding: 8px 22px;
      font-weight: 500;
    }
  }
  .journal-nav-item.active a {
    //border-bottom: 2px solid lighten($banner-color,8%);
    border-bottom: 2px solid #9475ff;
    padding-bottom: 1px;
    width: fit-content;
  }
  .journal-header a { color: white; }
  .journal-header a:hover, .journal-header a:focus {
    color: white; //lighten($banner-color,8%);
    text-decoration: underline;
  }
  .secondary-smallnav-item_link:hover, .secondary-smallnav-item_link:focus {
    //color: $banner-color;
    color: $highlights-color-light;
    text-decoration: none;
  }
  .journal-search-btn {
    background: $banner-color;
    //background: -moz-linear-gradient(top, #9908ff 0%, #7800cc 100%);
    //background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #9908ff), color-stop(100%, #7800cc));
    //background: -webkit-linear-gradient(top, #9908ff 0%, #7800cc 100%);
    //background: -o-linear-gradient(top, #9908ff 0%, #7800cc 100%);
    //background: -ms-linear-gradient(top, #9908ff 0%, #7800cc 100%);
    //background: linear-gradient(to bottom, #9908ff 0%, #7800cc 100%);
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9908ff', endColorstr='#7800cc',GradientType=0 );
  }
  .circle-journal {
    //border: 2px solid $banner-color !important;
    //background: $banner-color !important;
    border: 2px solid $highlights-color !important;
    background: $highlights-color !important;
  }
  .circle-journal.hovered {
    background: #fff !important;
    color: $highlights-color !important;
  }
  .icon-journal-color {
    //color: $banner-color !important;
    color: $highlights-color !important;
}
  .journal-header {
    background-image: url("/images/banners/" + $banner-path);
    background-size: cover;
  }
  .feature-issues-grid {
    //border-top: 4px solid $banner-color;
    border-top: 4px solid $highlights-color;
    //border-top: 4px solid #545454;
    border-right: none;
    border-left: none;
    border-bottom: none;
    //background-image: radial-gradient(farthest-corner at 100% 100%, #ccc, #eee, #eee, #eee);
  }
  /*.feature-issues-grid:hover {
    border-top: 4px solid lighten($banner-color,15%);
    //box-shadow: 0 2px 6px #ccc;
  }*/
/*  .sidebar-nav-i {
    &:last-child {
      border-bottom: 1px solid $banner-color !important;
    }
  }*/
}

$journal-color-opg: #7800cc;
$journal-color-ltopg: #9475ff;
$journal-color-ltgray: #d4d4d4;
$journal-color-on: #2c489f;
$journal-color-ao: #001869;
$journal-color-aop: #1e3770;
$journal-color-boe: #64050e;
$journal-color-josaa: #4b1f5c;
$journal-color-josab: #23592e;
$journal-color-oe: #94250f;
$journal-color-ol: #405d90;
$journal-color-ome: #0f7bb2;
$journal-color-optica: #022953;
$journal-color-prj: #731d29;
$journal-color-spotlight: #0f0923;
$journal-color-vjbo: #645194;
$journal-color-osac: #006e90;
// PARTNER JOURNALS
$journal-color-as: #535762;
$journal-color-col: #56968b;
$journal-color-jdt: #894276;
$journal-color-jlt: #4f7abf;
$journal-color-jocn: #8c8f2c;
$journal-color-josk: #028c9f;
$journal-color-copp: #028c9f;
$journal-color-jot: #4a8c67;
$journal-color-jnirs: #11100b;
$journal-color-josa: #a18553;

.journal-default-colors,
.conference-default-colors,
.journal-on {
  @include journal-color($journal-color-on,'ON-Homebanner.jpg');
}

// OSA Journals
.journal-ao {
  @include journal-color($journal-color-ao,'AO-Homebanner.jpg');
}

.journal-aop {
  @include journal-color($journal-color-aop,'AOP-Homebanner.jpg');
}

.journal-boe {
  @include journal-color($journal-color-boe,'BOE-Homebanner.jpg');
}

.journal-josaa {
  @include journal-color($journal-color-josaa,'JOSAA-Homebanner.jpg');
}

.journal-josab {
  @include journal-color($journal-color-josab,'JOSAB-Homebanner.jpg');
}

.journal-oe {
  @include journal-color($journal-color-oe,'OE-Homebanner.jpg');
}

.journal-ol {
  @include journal-color($journal-color-ol,'OL-Homebanner.jpg');
}

.journal-ome {
  @include journal-color($journal-color-ome,'OME-Homebanner.jpg');
}

.journal-optica {
  @include journal-color($journal-color-optica);
}

.journal-prj {
  @include journal-color($journal-color-prj,'PRJ-Homebanner.jpg');
}

.journal-spotlight {
  @include journal-color($journal-color-spotlight);
}

.journal-vjbo {
  @include journal-color($journal-color-vjbo);
}

.journal-osac {
  @include journal-color($journal-color-osac,'OSAC-Homebanner.jpg');
}

// PARTNER JOURNALS
.journal-as {
  @include journal-color($journal-color-as,'AS-Homebanner.jpg');
}

.journal-col {
  @include journal-color($journal-color-col,'COL-Homebanner.jpg');
}

.journal-jdt {
  @include journal-color($journal-color-jdt,'JDT-Homebanner.jpg');
}

.journal-jlt {
  @include journal-color($journal-color-jlt,'JLT-Homebanner.jpg');
}

.journal-jocn {
  @include journal-color($journal-color-jocn,'JOCN-Homebanner.jpg');
}

.journal-josk {
  @include journal-color($journal-color-josk);
}

.journal-copp {
  @include journal-color($journal-color-copp,'COPP-Homebanner.jpg');
}

.journal-jot {
  @include journal-color($journal-color-jot,'JOT-Homebanner.jpg');
}

.journal-jnirs {
  @include journal-color($journal-color-jnirs,'JNIRS-Homebanner.jpg');
}
// RETIRED JOURNALS
// .journal-jon // Integrated with JOCN in the browse issues
// .journal-opn // Browse issues homepage using generic OIB header

.journal-josa {
  @include journal-color($journal-color-josa,'JOSA-Homebanner.jpg');
}

// SPOTLIGHT ON OPTICS

.spotlight {
  @include journal-color(#535e81);
}

// For journal-color-styleguide.html
.color-swatch {
  width: 100%;
  display: block;
  clear: both;
  overflow: hidden;
  margin-bottom: 1em;
  .banner-color,
  .font-color,
  .hover-color {
    height: 50px;
    width: 50px;
    float: left;
    margin-right: 1em;
  }
}

// SVG icon filter color https://codepen.io/sosuke/pen/Pjoqqp
.filter-opg { filter: invert(11%) sepia(85%) saturate(7495%) hue-rotate(278deg) brightness(80%) contrast(115%); }
.filter-ltopg { filter: invert(45%) sepia(92%) saturate(451%) hue-rotate(210deg) brightness(99%) contrast(106%); }
.filter-ltgray {filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7179%) hue-rotate(189deg) brightness(71%) contrast(163%); }
.filter-white { filter: invert(89%) sepia(100%) saturate(0%) hue-rotate(144deg) brightness(107%) contrast(105%); }
.filter-on { filter: invert(15%) sepia(94%) saturate(2685%) hue-rotate(225deg) brightness(88%) contrast(80%); }
.filter-ao { filter: invert(9%) sepia(33%) saturate(7083%) hue-rotate(222deg) brightness(101%) contrast(112%); }
.filter-aop { filter: invert(17%) sepia(16%) saturate(6836%) hue-rotate(205deg) brightness(89%) contrast(90%); }
.filter-boe { filter: invert(14%) sepia(34%) saturate(5820%) hue-rotate(339deg) brightness(71%) contrast(109%); }
.filter-josaa { filter: invert(15%) sepia(15%) saturate(5764%) hue-rotate(256deg) brightness(98%) contrast(97%); }
.filter-josab { filter: invert(28%) sepia(29%) saturate(845%) hue-rotate(80deg) brightness(95%) contrast(95%); }
.filter-oe { filter: invert(18%) sepia(54%) saturate(3470%) hue-rotate(355deg) brightness(86%) contrast(94%); }
.filter-ol { filter: invert(35%) sepia(52%) saturate(541%) hue-rotate(180deg) brightness(88%) contrast(89%); }
.filter-ome { filter: invert(35%) sepia(59%) saturate(1108%) hue-rotate(165deg) brightness(96%) contrast(93%); }
.filter-optica { filter: invert(15%) sepia(18%) saturate(4916%) hue-rotate(187deg) brightness(90%) contrast(107%); }
.filter-prj { filter: invert(17%) sepia(17%) saturate(5929%) hue-rotate(323deg) brightness(94%) contrast(98%); }
.filter-spotlight { filter: invert(4%) sepia(87%) saturate(1392%) hue-rotate(235deg) brightness(106%) contrast(102%); }
.filter-vjbo { filter: invert(35%) sepia(17%) saturate(1486%) hue-rotate(216deg) brightness(94%) contrast(90%); }
.filter-osac { filter: invert(24%) sepia(85%) saturate(1775%) hue-rotate(171deg) brightness(94%) contrast(101%); }
// PARTNER JOURNALS
.filter-as { filter: invert(34%) sepia(18%) saturate(302%) hue-rotate(186deg) brightness(92%) contrast(88%); }
.filter-col { filter: invert(55%) sepia(10%) saturate(1408%) hue-rotate(120deg) brightness(95%) contrast(88%); }
.filter-jdt { filter: invert(33%) sepia(55%) saturate(567%) hue-rotate(264deg) brightness(84%) contrast(89%); }
.filter-jlt { filter: invert(43%) sepia(6%) saturate(4480%) hue-rotate(178deg) brightness(105%) contrast(83%); }
.filter-jocn { filter: invert(61%) sepia(10%) saturate(3132%) hue-rotate(23deg) brightness(87%) contrast(72%); }
.filter-josk { filter: invert(37%) sepia(41%) saturate(2311%) hue-rotate(156deg) brightness(92%) contrast(98%); }
.filter-copp { filter: invert(37%) sepia(41%) saturate(2311%) hue-rotate(156deg) brightness(92%) contrast(98%); }
.filter-jot { filter: invert(47%) sepia(49%) saturate(369%) hue-rotate(94deg) brightness(93%) contrast(86%); }
.filter-jnirs { filter: invert(6%) sepia(11%) saturate(1050%) hue-rotate(12deg) brightness(87%) contrast(99%); }
.filter-josa { filter: invert(50%) sepia(64%) saturate(273%) hue-rotate(360deg) brightness(93%) contrast(89%); }

.conf-header a:hover, .conf-header a:focus {
  color: white; //lighten($banner-color,8%);
  text-decoration: underline;
}
.conf-header-abbr {
  border-bottom: 2px solid $journal-color-ltopg;
  padding-bottom: 2px;
  width: fit-content;
}
